import { Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * z-index 관리 및 trackBy 위한 내부 변수
 */
let dialogIndex = -1;

/**
 * Dialog Reference interface
 *
 * @param T Component type
 * @param R Return type
 * @param D Input data type
 */
export interface IDialogRef<T, R = any, D = any> {
  type: Type<T>;

  /**
   * Dialog 여는 중
   */
  onDialogOpening: Observable<T>;

  /**
   * Dialog 열림
   */
  onDialogOpened: Observable<T>;

  /**
   * Dialog 닫는 중
   */
  onDialogClosing: Observable<R | undefined>;

  /**
   * Dialog 닫힘
   */
  onDialogClosed: Observable<R | undefined>;

  /**
   * Dialog 닫기 막힘
   */
  onDialogClosePrevented: Observable<void>;

  options?: Partial<any>;

  data?: D;

  toggle(relatedTarget?: IDialogRef<any>): void;

  close(result?: R): void;
}

/**
 * Dialog Reference
 *
 * @param T Component type
 * @param R Return type
 * @param D Input data type
 */
export abstract class DialogRef<T, R = any, D = any>
  implements IDialogRef<T, R, D>
{
  type: Type<T>;

  /**
   * Dialog 여는 중
   */
  onDialogOpening: Subject<T> = new Subject();

  /**
   * Dialog 열림
   */
  onDialogOpened: Subject<T> = new Subject();

  /**
   * Dialog 닫는 중
   */
  onDialogClosing: Subject<R | undefined> = new Subject();

  /**
   * Dialog 닫힘
   */
  onDialogClosed: Subject<R | undefined> = new Subject();

  /**
   * Dialog 닫기 막힘
   */
  onDialogClosePrevented: Subject<void> = new Subject();

  id: number;

  isBlockShowing?: boolean;

  hidden?: boolean;

  options?: Partial<any>;

  data?: D;

  result?: R;

  modal?: any;

  element?: HTMLElement;

  constructor(type: Type<T>, options?: any, data?: D) {
    dialogIndex += 1;
    this.id = dialogIndex;
    this.type = type;
    this.options = options;
    this.data = data;
  }

  /**
   * Dialog 토글
   */
  abstract toggle(relatedTarget?: IDialogRef<any>): void;

  /**
   * Dialog 닫기
   */
  abstract close(result?: R): void;
}
