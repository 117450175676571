import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandService } from '../services/brand.service';

export const BRAND_CD = new InjectionToken<string>('brandCd');
export const BRAND_ID = new InjectionToken<string>('brandId');
export const BRAND_IGNORE_PATH_LIST = new InjectionToken<string[]>(
  'brandIgnorePathList',
);

class BrandInterceptorFn {
  static #brandService: BrandService;

  static #ignorePathList: string[];

  private static get brandService(): BrandService {
    if (!BrandInterceptorFn.#brandService) {
      BrandInterceptorFn.#brandService = inject(BrandService);
    }

    return BrandInterceptorFn.#brandService;
  }

  private static get ignorePathList(): string[] {
    if (!BrandInterceptorFn.#ignorePathList) {
      BrandInterceptorFn.#ignorePathList = inject(BRAND_IGNORE_PATH_LIST);
    }

    return BrandInterceptorFn.#ignorePathList;
  }

  static interceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
  ): Observable<HttpEvent<unknown>> {
    if (
      req.url.startsWith(environment.apiServerUrl) &&
      !req.url.includes('/pms/') &&
      !BrandInterceptorFn.ignorePathList!.some((path) => req.url.includes(path))
    ) {
      let { headers, params } = req;

      if (BrandInterceptorFn.brandService.brand?.id != null) {
        headers = req.headers.set(
          'Brand-Id',
          `${BrandInterceptorFn.brandService.brand.id}`,
        );
        // params = req.params.set('brandId', brandId);
      }

      // TODO: 표준화 검토
      // if (config.serviceCode) {
      //   // 표시 서비스
      //   params = req.params.set('dpServiceList', config.serviceCode);
      // }

      return next(
        req.clone({
          // API 서버 CorsFilter에서 Access-Control-Allow-Headers에 Brand-Id 추가 필요
          headers,
          params,
        }),
      );
    }

    return next(req);
  }
}

/**
 * 브랜드 인터셉터 Fn
 */
export const brandInterceptor: HttpInterceptorFn =
  BrandInterceptorFn.interceptor;
