<footer class="footer-wrap" [class]="'type-' + footerType">
  <div class="footer" *ngIf="footerType !== '2'">
    <div class="row">
      <div class="logo-wrapper">
        <img class="brand-logo object-fit-contain" [src]="logoImgUrl" />
        <table class="menu-table">
          <ng-container>
            <thead>
              <tr>
                <!-- <th>
                  {{ brand.brandNm }}
                </th> -->
                <th>{{ 'inquiryAndAddress' | translate }}</th>
                <!-- <th>정보</th> -->
              </tr>
            </thead>
          </ng-container>
          <tbody>
            <tr>
              <ng-container [ngTemplateOutlet]="brandInfo"></ng-container>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="menu-table-mobile">
        <tbody>
          <tr>
            <td>
              <ng-container [ngTemplateOutlet]="brandInfo"></ng-container>
            </td>
          </tr>
          <tr class="copyright" translate="no">
            <td>COPYRIGHT ⓒ PAYWITH. ALL RIGHTS RESERVED</td>
          </tr>
        </tbody>
      </table>
      <ng-container [ngTemplateOutlet]="topButton"></ng-container>
    </div>
    <div class="row">
      <!-- <a class="logo" routerLink="/home">
        <img [src]="brand.logoImgUrl" />
      </a> -->
    </div>
  </div>

  <div class="footer" *ngIf="footerType === '2'">
    <div class="row row-top">
      <a class="logo" routerLink="/home">
        <img [src]="logoImgUrl" />
      </a>
      <div class="brand-info-wrapper">
        <ng-container [ngTemplateOutlet]="brandInfo"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="topButton"></ng-container>
    </div>
    <div class="row">
      <ng-container [ngTemplateOutlet]="brandInfo"></ng-container>
    </div>
    <!-- TODO: SNS -->
  </div>

  <ng-template #brandInfo>
    <ul class="brand-info-list">
      <li class="brand-info">
        <span>{{
          footerConfig?.['corpName'] || (brand | locale: 'brandNm')
        }}</span>
        @if (footerConfig?.['corpCeoName'] || brandOktaMember.memberName) {
          <span
            >{{ 'CEO' | translate }} :
            {{
              footerConfig?.['corpCeoName'] || brandOktaMember.memberName
            }}</span
          >
        }
        @if (footerConfig?.['corpAddress'] || brand?.address) {
          <span
            >{{ 'Address' | translate }} :
            {{ footerConfig?.['corpAddress'] || brand?.address }}</span
          >
        }
      </li>
      <li class="brand-info extra-info" *ngIf="footerConfig?.['extraInfo']">
        <span [innerText]="footerConfig?.['extraInfo']"></span>
      </li>
    </ul>
  </ng-template>

  <ng-template #topButton>
    <div class="top" translate="no">
      <button class="top-button" type="button" (click)="onTopButtonClick()">
        <svg class="icon" width="46" height="46">
          <use href="/assets/imgs/ic_top.svg#path"></use>
        </svg>
        <span class="label">TOP</span>
      </button>
    </div>
  </ng-template>
</footer>
