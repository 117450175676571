import { Injectable } from '@angular/core';
import tinycolor, { Instance } from 'tinycolor2';

export interface Color {
  name: string;
  hex: string;
  rgb: tinycolor.ColorFormats.RGBA;
  darkContrast: boolean;
}

/**
 * Color Theme 가변 적용
 *
 * ref:
 * https://medium.com/angular-in-depth/build-truly-dynamic-theme-with-css-variables-539516e95837
 * https://github.com/zhaosiyang/angular-material-dynamic-theme
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicColorService {
  primaryColorPalette: Color[] = [];

  secondaryColorPalette: Color[] = [];

  tertiaryColorPalette: Color[] = [];

  dangerColorPalette: Color[] = [];

  constructor() {}

  getColorObject(value: Instance, name: string): Color {
    const c = tinycolor(value);
    return {
      name,
      hex: c.toHexString(),
      rgb: c.toRgb(),
      darkContrast: c.isLight(),
    };
  }

  computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  setPrimaryColor(color: string): void {
    this.primaryColorPalette = this.computeColors(color);

    const color500 = this.primaryColorPalette[5];

    this.setColor('primary', color500);
  }

  setSecondaryColor(color: string): void {
    this.secondaryColorPalette = this.computeColors(color);

    const color500 = this.secondaryColorPalette[5];

    this.setColor('secondary', color500);
  }

  setTertiaryColor(color: string): void {
    this.tertiaryColorPalette = this.computeColors(color);

    const color500 = this.tertiaryColorPalette[5];

    this.setColor('tertiary', color500);
  }

  setDangerColor(color: string): void {
    this.dangerColorPalette = this.computeColors(color);

    const color500 = this.dangerColorPalette[5];

    this.setColor('danger', color500);
  }

  setColor(type: string, color: Color): void {
    // Bootstrap 색상 적용
    const bsColorKey = `--bs-${type}`;
    const bsColorValue = color.hex;

    this.setDocumentStyleProperty(bsColorKey, bsColorValue);

    // Bootstrap 색상 적용
    const bsSecondaryRgbKey = `--bs-${type}-rgb`;
    const bsSecondaryRgbValue = `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}`;

    this.setDocumentStyleProperty(bsSecondaryRgbKey, bsSecondaryRgbValue);

    const textEmphasis = this.shadeColor(color, 80);

    // Bootstrap 색상 적용
    const bsTextEmphasisKey = `--bs-${type}-text-emphasis`;
    const bsTextEmphasisValue = textEmphasis.hex;

    this.setDocumentStyleProperty(bsTextEmphasisKey, bsTextEmphasisValue);

    const bgSubtle = this.tintColor(color, 80);

    // Bootstrap 색상 적용
    const bsBgSubtleKey = `--bs-${type}-bg-subtle`;
    const bsBgSubtleValue = bgSubtle.hex;

    this.setDocumentStyleProperty(bsBgSubtleKey, bsBgSubtleValue);

    const borderSubtle = this.tintColor(color, 60);

    // Bootstrap 색상 적용
    const bsBorderSubtleKey = `--bs-${type}-border-subtle`;
    const bsBorderSubtleValue = borderSubtle.hex;

    this.setDocumentStyleProperty(bsBorderSubtleKey, bsBorderSubtleValue);

    (this[`${type}ColorPalette` as keyof DynamicColorService] as Color[]).forEach((c) => {
      const key1 = `--${type}-color-${c.name}`;
      const value1 = c.hex;

      this.setDocumentStyleProperty(key1, value1);

      const key2 = `--on-${type}-color-${c.name}`;
      const value2 = c.darkContrast ? 'rgba(black, 0.87)' : 'white';

      this.setDocumentStyleProperty(key2, value2);
    });
  }

  setDocumentStyleProperty(key: string, value: string): void {
    document.documentElement.style.setProperty(key, value);
  }

  tintColor(color: Color, weight: number): Color {
    const colorInstance = tinycolor.mix('white', color.hex, weight);

    return {
      name: color.name,
      hex: colorInstance.toHexString(),
      rgb: colorInstance.toRgb(),
      darkContrast: colorInstance.isLight(),
    };
  }

  shadeColor(color: Color, weight: number): Color {
    const colorInstance = tinycolor.mix('black', color.hex, weight);

    return {
      name: color.name,
      hex: colorInstance.toHexString(),
      rgb: colorInstance.toRgb(),
      darkContrast: colorInstance.isLight(),
    };
  }
}
