import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../../../lib/pipes/safe-html.pipe';
import { DialogRef } from '../../dialog-ref';
import { DialogAbstract } from '../../dialog.abstract';
import { IAlertDialogData } from './alert-dialog.data';

export type AlertDialogRef = DialogRef<
  // eslint-disable-next-line no-use-before-define
  AlertDialogComponent,
  boolean | null,
  IAlertDialogData
>;

@Component({
  selector: 'trnty-alert-dialog',
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe, TranslateModule],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss',
})
export class AlertDialogComponent extends DialogAbstract {
  override dialogRef!: AlertDialogRef;

  get hasButtonData(): boolean {
    return !!(
      this.dialogRef.data?.positiveButton ||
      this.dialogRef.data?.negativeButton ||
      this.dialogRef.data?.neutralButton
    );
  }

  onClickPositive(): void {
    this.close(this.dialogRef.data?.positiveButton?.value ?? true);
  }

  onClickNegative(): void {
    this.close(this.dialogRef.data?.negativeButton?.value ?? false);
  }

  onClickNeutral(): void {
    this.close(this.dialogRef.data?.neutralButton?.value ?? null);
  }
}
