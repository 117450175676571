<div class="container py-5">
  @if (booking && hotel && tmBookingDetail) {
    <p class="display-6 m-0">
      @if (tmBookingStatus === 'canceled') {
        <span class="text-danger">
          {{ 'tm.booking.status.cancel' | translate }}
        </span>
      } @else if (tmBookingStatus === 'paying') {
        <span class="text-danger">
          {{ 'tm.booking.status.paying' | translate }}
        </span>

        @if (paymentUrl) {
          <div>
            <button
              class="btn btn-outline-secondary"
              (click)="onPayClick()"
              *ngIf="tmBookingStatus === 'paying' && paymentUrl"
            >
              {{ 'pay' | translate }}
            </button>
          </div>
        }
      } @else if (tmBookingStatus === 'complete') {
        @if (isAlreadyCheckOut) {
          <span>
            {{ 'tm.booking.status.alreadyCheckOut' | translate }}
          </span>
        } @else {
          <span class="text-primary">
            {{ 'tm.booking.status.complete' | translate }}
          </span>
        }
      }
    </p>

    <p class="mb-5">
      <small class="text-body-secondary">
        {{ 'notResponsibleToThisGoods' | translate: { brandNm } }}
      </small>
    </p>

    <h3 class="link-primary d-inline-block">
      <a [routerLink]="['/tmmrhst', mrhstId]">
        {{ hotel.Name }}
      </a>
    </h3>

    <!-- 주소, 전화번호는 일부러 번역 안함 -->
    <p class="text-body-secondary">
      <i class="bi bi-geo-alt"></i>

      <span translate="no">
        {{ hotel.Address }}
      </span>

      <br />

      <i class="bi bi-telephone"></i>

      <span translate="no">
        {{ hotel.PhoneNum }}
      </span>
    </p>

    <div class="mb-5">
      <trnty-img-grid [imgUrlList]="imgUrlList"></trnty-img-grid>
    </div>

    <h4>
      {{ 'myBooking' | translate }}
    </h4>

    <div class="row mb-5 g-3">
      <div class="col-6 col-md">
        <label class="fs-6"> {{ 'bookingId' | translate }} </label>

        <div class="fw-bold fs-5">
          {{ tmBookingDetail.BookingId }}
        </div>
      </div>

      <div class="col-6 col-md">
        <label class="fs-6">{{ 'personCnt' | translate }} </label>

        <div class="fw-bold fs-5">
          {{ 'unit.people' | translate: { cnt: tmBookingDetail.AdultPax } }},
          {{ 'unit.room' | translate: { cnt: tmBookingDetail.RoomNum } }}
        </div>
      </div>

      <div class="col-6 col-md">
        <label class="fs-6">
          {{ 'Check In' | translate }}
        </label>

        <div class="fw-bold fs-5">
          {{ tmBookingDetail.CheckInDate | date: 'yyyy-MM-dd' }}
          <br />
          {{ tmBookingDetail.CheckInTime }}
        </div>
      </div>

      <div class="col-6 col-md">
        <label class="fs-6">
          {{ 'Check Out' | translate }}
        </label>

        <div class="fw-bold fs-5">
          {{ tmBookingDetail.CheckOutDate | date: 'yyyy-MM-dd' }}
          <br />
          {{ tmBookingDetail.CheckOutTime }}
        </div>
      </div>

      <div class="col-12 col-md">
        <label class="fs-6">
          {{ 'setleAmt' | translate }}
        </label>

        <div class="fw-bold fs-5">
          ¥{{ tmBookingDetail.TotalPrice | number }}
        </div>
      </div>
    </div>

    @if (room) {
      <h4>{{ 'Room' | translate }}</h4>

      <table class="table table-borderless mb-5">
        <tbody class="fs-0875 fs-md-6">
          <tr>
            <th class="w-25">{{ 'Room' | translate }}</th>
            <td [innerHTML]="tmBookingDetail.RoomName"></td>
          </tr>

          <tr>
            <th class="w-25">{{ 'plan' | translate }}</th>
            <td [innerHTML]="tmBookingDetail.PlanName"></td>
          </tr>

          <tr>
            <th class="w-25">{{ 'roomType' | translate }}</th>
            <td>
              {{ room | tmEnum: 'RoomCategory' }}
              {{ room | tmEnum: 'RoomType' : { exclude: [8] } }}
            </td>
          </tr>

          <tr>
            <th class="w-25">{{ 'Capacity' | translate }}</th>
            <td>{{ 'unit.people' | translate: { cnt: room.MaxPax } }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'room.equipment' | translate }}</th>
            <td>{{ room | tmEnum: 'Equipment' }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'room.amenity' | translate }}</th>
            <td>{{ room | tmEnum: 'Amenity' }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'etc' | translate }}</th>
            <td>
              {{ room | tmEnum: 'Feature' }}
            </td>
          </tr>

          <tr>
            <th class="w-25">{{ 'Name' | translate }}</th>
            <td translate="no">
              {{ tmBookingDetail.Surname }} {{ tmBookingDetail.FirstName }}
            </td>
          </tr>
        </tbody>
      </table>
    }

    <h4 class="mb-0">
      {{ 'cancelRule' | translate }}
    </h4>

    <p>
      <small class="text-body-secondary">
        {{ 'refundText' | translate }}
      </small>
    </p>

    <div class="mb-5">
      <app-tm-cancel-policy-table
        [cancelPolicy]="plan.CancelPolicy"
      ></app-tm-cancel-policy-table>
    </div>

    @if (tmBookingDetail && tmBookingDetail.Cancelled === 0) {
      <!-- 가운데 중앙 정렬된 취소 버튼 -->
      <div class="d-grid col-4 mx-auto">
        <!-- 눈에 덜 띄는 색으로 지정 -->
        <button
          class="btn btn-outline-secondary btn-lg"
          (click)="onCancelClick()"
        >
          {{ 'BUTTON.Cancel_Booking' | translate }}
        </button>
      </div>
    }
  }
</div>
