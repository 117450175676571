import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { MenuApiService } from 'src/lib/repository/menu/menu.api';
import { IUserCard } from 'src/lib/repository/user-card/user-card.model';
import { IUserInfo } from 'src/lib/repository/user-info/user-info.model';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { DialogService } from '../../services/dialog.service';
import { UiService } from '../../services/ui.service';
import { LoginComponent } from '../dialog/login/login.component';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  // 메뉴 마스터 목록
  readonly menuListNew = this.menuApiService.menuList;

  // 가입 가능 여부
  canRegister = this.homeScreenService.homeScreen.userSignUp;

  /**
   * 로그인 여부
   */
  isLoggedIn?: boolean;

  /**
   * 회원 정보
   */
  @Input()
  userInfo: IUserInfo | null = null;

  /**
   * 포인트 조회용 회원 카드
   */
  @Input()
  userCard: IUserCard | null = null;

  @Output()
  refreshInfoClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private menuApiService: MenuApiService,
    private uiService: UiService,
    private homeScreenService: HomeScreenService,
  ) {}

  ngOnInit(): void {
    this.setUserInfo();
  }

  onLogoutClick(): void {
    this.authService.logout();
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }

  onLoginClick(): void {
    this.login().subscribe();
  }

  onRegisterClick(): void {
    if (this.canRegister) {
      this.router.navigateByUrl('/join');
    } else {
      this.dialogService.alert('ALERT.Req_Register_Travel_Agency').subscribe();
    }
    // else if (this.cantRegisterMsg) {
    //   this.dialogService.alert(this.cantRegisterMsg);
    // }
  }

  /**
   * 회원 정보 갱신
   */
  onRefreshInfoButtonClick(): void {
    this.refreshInfoClick.emit();
  }

  /**
   * 크기별 메뉴 표시 여부
   */
  isMenuVisibleByBreakpoint(breakpoint?: string): boolean {
    return this.uiService.isVisibleByBreakpoint(breakpoint);
  }

  /**
   * 회원 정보 설정
   */
  private setUserInfo(): void {
    if (this.authService.account) {
      this.isLoggedIn =
        this.authService.account?.userInfo?.certType !== 'GUEST';
    }

    this.authService.loginSubject$
      .asObservable()
      .pipe(
        tap(() => {
          this.isLoggedIn =
            this.authService.account != null &&
            this.authService.account?.userInfo?.certType !== 'GUEST';
        }),
      )
      .subscribe();
  }

  /**
   * boolean을 반환하는 로그인 모달
   *
   * 로그인 했으면 무조건 true
   */
  private login(): Observable<boolean> {
    if (this.authService.account) {
      return of(true);
    }

    return this.dialogService.open(LoginComponent).onDialogClosed;
  }
}
