import { AfterViewInit, Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { finalize, tap } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { AbstractCalendarDataComponent } from 'src/lib/components/abstract/abstract-calendar-data.component';
import { YearMonthChange } from 'src/lib/components/abstract/abstract-year-month-selector.component';
import { TemairazuPicture } from 'src/lib/repository/temairazu/temairazu.model';
import {
  TmHotel,
  TmInventory,
  TmInventoryRoom,
  TmRoom,
} from 'src/lib/repository/temairazu/tm-plan.model';
import { TmRoomplanApi } from 'src/lib/repository/temairazu/tm-roomplan.api';
import { TmTopApi } from 'src/lib/repository/temairazu/tm-top.api';
import { DATE_FORMAT, Utils } from 'src/lib/utils';
import { MrhstApi } from '../../../../lib/repository/mrhst/mrhst.api';
import { DialogService } from '../../../services/dialog.service';
import { BookingCalendarType1Component } from '../../booking-calendar/booking-calendar-type1/booking-calendar-type1.component';
import { BookingCalendarYearMonthSelectorComponent } from '../../booking-calendar/booking-calendar-year-month-selector/booking-calendar-year-month-selector.component';
import { BookingCalendarModule } from '../../booking-calendar/booking-calendar.module';
import { TemairazuCalendarDataComponent } from '../../booking-calendar/temairazu-calendar-data/temairazu-calendar-data.component';
import {
  HotelGoodsSearchComponent,
  HotelGoodsSearchParams,
} from '../../hotel-goods-search/hotel-goods-search.component';
import { HotelGoodsSearchModule } from '../../hotel-goods-search/hotel-goods-search.module';
import { ImgGridComponent } from '../../img-grid/img-grid.component';
import { ImgViewerModalComponent } from '../../img-viewer-modal/img-viewer-modal.component';
import { SharedModule } from '../../shared/shared.module';

/**
 * 테마이라즈 시설 상세 페이지 컴포넌트
 */
@Component({
  selector: 'trnty-tm-mrhst-detail',
  standalone: true,
  imports: [
    SharedModule,
    HotelGoodsSearchModule,
    BookingCalendarModule,
    ImgGridComponent,
  ],
  templateUrl: './tm-mrhst-detail.component.html',
  styleUrl: './tm-mrhst-detail.component.scss',
})
export class TmMrhstDetailComponent implements AfterViewInit {
  /**
   * 시설 식별번호
   */
  mrhstId: number;

  /**
   * 테마이라즈 호텔
   */
  hotel!: TmHotel;

  /**
   * 테마이라즈 객실 목록
   */
  roomList: TmRoom[] = [];

  /**
   * 테마이라즈 객실 식별번호별 재고 맵
   */
  roomInventoryMap: Map<number, TmInventoryRoom> = new Map();

  /**
   * 검색 컴포넌트
   */
  searchComponent!: HotelGoodsSearchComponent;

  /**
   * 년월 선택 컴포넌트
   */
  yearMonthSelectorComponent!: BookingCalendarYearMonthSelectorComponent;

  /**
   * 달력 컴포넌트
   */
  calendarComponent!: BookingCalendarType1Component;

  /**
   * 달력 데이터 컴포넌트
   */
  calendarDataComponent = TemairazuCalendarDataComponent;

  /**
   * 달력 일자 목록
   */
  dateList?: string[];

  /**
   * 달력 데이터
   */
  componentData?: TmInventory[];

  /**
   * 테마이라즈 호텔 이미지 목록
   */
  imgUrlList: string[] = [];

  mrhstDirections?: string;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private tmTopApi: TmTopApi,
    private tmRoomplanApi: TmRoomplanApi,
    private mrhstApi: MrhstApi,
  ) {
    const { id } = this.activatedRoute.snapshot.params;
    this.mrhstId = id;
    this.setMrhst();
    this.setTemairazuData();
  }

  ngAfterViewInit(): void {
    this.setBookableFirstDate();
    this.onSearchClick();
    // this.setCalendarData();

    const { checkInDt } = this.searchComponent.getValue();

    if (checkInDt) {
      this.calendarComponent.selectedDate = checkInDt;
      this.setInventory(this.searchComponent.getValue());
    }
  }

  /**
   * 검색기 초기화시
   */
  onSearchInit(component: HotelGoodsSearchComponent): void {
    this.searchComponent = component;
    this.searchComponent.setValue(this.activatedRoute.snapshot.queryParams);
  }

  /**
   * 년월 선택기 초기화시
   */
  onYearMonthSelectorInit(
    component: BookingCalendarYearMonthSelectorComponent,
  ): void {
    this.yearMonthSelectorComponent = component;
  }

  /**
   * 달력 초기화시
   */
  onCalendarInit(component: BookingCalendarType1Component): void {
    this.calendarComponent = component;
  }

  /**
   * 공실 검색 클릭시
   */
  onSearchClick(): void {
    if (!this.searchComponent) {
      return;
    }

    const { checkOutDt, ...rest } = this.searchComponent.getValue();

    this.router.navigate([], {
      queryParams: rest,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });

    this.setCalendarData();
  }

  /**
   * 년월 변경시
   */
  onYearMonthChange({ fromDate, toDate }: YearMonthChange): void {
    this.dateList = Utils.getDateList(fromDate, toDate);
    this.setCalendarData();
  }

  /**
   * 달력 일자 클릭시
   */
  onCalendarDateClick(component: AbstractCalendarDataComponent): void {
    if (!component || component.isDisabled) {
      return;
    }

    const searchValue = {
      ...this.searchComponent.getValue(),
      checkInDt: component.date,
    };
    this.searchComponent.setValue(searchValue);

    // 쿼리파라미터 변경
    this.router.navigate([], {
      queryParams: this.searchComponent.getValue(),
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });

    this.setInventory(this.searchComponent.getValue());
  }

  /**
   * 이미지 클릭시
   */
  onRoomImgClick(index: number, tmPictureList: TemairazuPicture[]): void {
    const imgUrlList = tmPictureList.map(({ Url }) => Url);
    const data = {
      index,
      imgUrlList,
    };

    this.dialogService.open(ImgViewerModalComponent, { data });
  }

  /**
   * 객실, 플랜 선택 클릭시
   */
  onRoomSelectClick(roomId: number): void {
    this.router.navigate(['booking'], {
      queryParams: { roomId },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  private setMrhst(): void {
    this.mrhstApi
      .getTemairazuMrhst(this.mrhstId)
      .pipe(
        tap((mrhst) => {
          this.mrhstDirections = Utils.getParsedJson(
            mrhst.descriptionJson,
            {},
          ).directions;
        }),
      )
      .subscribe();
  }

  /**
   * 테마이라즈 데이터 설정
   */
  private setTemairazuData(): void {
    this.tmTopApi
      .getHotel(this.mrhstId)
      .pipe(
        tap(({ Hotel }) => {
          this.hotel = Hotel;
          this.imgUrlList = this.hotel.Pictures.map(({ Url }) => Url);
        }),
      )
      .subscribe();

    this.tmRoomplanApi
      .getRoomList(this.mrhstId)
      .pipe(
        tap((response) => {
          this.roomList = response.Rooms;
        }),
      )
      .subscribe();
  }

  /**
   * 패키지 설정에 따라 예약 가능한 첫번째 날 설정, 달력을 포커싱
   */
  private setBookableFirstDate(): void {
    // 오늘
    const todayMs = dayjs().valueOf();
    const { checkInDt } = this.searchComponent.getValue();
    // 예약 가능 시작일 패키지 설정
    const startDtFromMs = dayjs(checkInDt).valueOf();
    // 위 셋중 가장 마지막 날
    const theLatest = Math.max(todayMs, startDtFromMs);
    // 년월 선택기 값 설정
    this.yearMonthSelectorComponent!.setValue(dayjs(theLatest));
    // 달력에 입력할 일자 목록 설정
    const { fromDate, toDate } = this.yearMonthSelectorComponent!.getValue();
    this.dateList = Utils.getDateList(fromDate, toDate);
  }

  /**
   * 예약 달력 데이터 설정
   */
  private setCalendarData(): void {
    if (!this.searchComponent || !this.yearMonthSelectorComponent) {
      return;
    }

    const { fromDate, toDate } = this.yearMonthSelectorComponent.getValue();
    const { nights, roomCnt, tourNumber } = this.searchComponent.getValue();
    const fromDtMs = dayjs(fromDate).valueOf();
    const tomorrowMs = dayjs().add(1, 'day').valueOf();
    const maxMs = Math.max(fromDtMs, tomorrowMs);

    const inventoryParams = {
      from_date: dayjs(maxMs).format(DATE_FORMAT),
      to_date: toDate,
      stay_days: nights,
      rooms_num: roomCnt,
      adults_num: tourNumber,
    };

    this.tmRoomplanApi
      .getInventories(this.mrhstId, inventoryParams)
      .pipe(
        tap(({ Inventories }) => {
          this.componentData = Inventories;
        }),
      )
      .subscribe();
  }

  /**
   * 공실 조회
   */
  private setInventory(params: HotelGoodsSearchParams): void {
    if (this.loadingService.isLoading) {
      return;
    }

    const inventoryParams = {
      from_date: params.checkInDt,
      to_date: params.checkInDt,
      stay_days: params.nights,
      rooms_num: params.roomCnt,
      adults_num: params.tourNumber,
    };

    this.loadingService.start();

    this.tmRoomplanApi
      .getInventories(this.mrhstId, inventoryParams)
      .pipe(
        tap(({ Inventories }) => {
          this.roomInventoryMap = new Map();
          const [targetDate] = Inventories || [];

          // 객실 아이디별 객실 재고 입력
          targetDate?.Rooms?.forEach((room) => {
            this.roomInventoryMap.set(room.RoomId, room);
          });

          // 가격 싼 순으로 정렬
          this.roomList = this.roomList.sort((a, b) => {
            // 가격(재고) 없으면 맨 뒤로
            return (
              (this.getRoomTotalPrice(a.RoomId) || Infinity) -
              (this.getRoomTotalPrice(b.RoomId) || Infinity)
            );
          });
        }),
        finalize(() => {
          this.loadingService.stop();
        }),
      )
      .subscribe();
  }

  /**
   * 객실 가격 획득
   */
  private getRoomTotalPrice(roomId: number): number {
    return this.roomInventoryMap.get(roomId)?.MinTotalPrice || 0;
  }
}
