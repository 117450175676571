import { Location, NgComponentOutlet, ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  Router,
  RouterOutlet,
  Scroll,
} from '@angular/router';
import { fadeAnimation } from 'src/lib/animation';

import { filter, tap } from 'rxjs';
import { AppUpdateService } from 'src/lib/services/app-update.service';
import { AppService } from 'src/lib/services/bridge/app.service';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgComponentOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  UPDATE_MSG = '새 버전으로 업데이트 합니다';

  highlightLink = false;

  constructor(
    protected dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private appUpdateService: AppUpdateService,
    private location: Location,
    private viewportScroller: ViewportScroller,
  ) {
    this.registerRouterEvent();
  }

  ngOnInit(): void {
    this.updateAppStyle();
    this.appUpdateService.initUpdate();
  }

  private registerRouterEvent(): void {
    let previousUrl: string;

    this.router.events
      .pipe(
        filter((event: Event): event is Scroll => event instanceof Scroll),
        tap((event) => {
          const [currentUrl] = this.router.url.split('?');

          if (event.position) {
            // 후방향 라우팅(뒤로가기)
            this.viewportScroller.scrollToPosition(event.position);
          } else if (event.anchor) {
            // 앵커
            this.viewportScroller.scrollToAnchor(event.anchor);
          } else if (currentUrl !== previousUrl) {
            if (
              !(
                previousUrl?.startsWith('/package/') &&
                currentUrl.startsWith('/package/')
              )
            ) {
              // 전방향 라우팅
              this.viewportScroller.scrollToPosition([0, 0]);
            }
          }

          previousUrl = currentUrl;
        }),
      )
      .subscribe();
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  private updateAppStyle(): void {
    // this._statusBarService.setStatusBarBrightness('dark');
    // SafeArea 비활성화
    // this._appService.useSafeArea(true);
    // 기본 뒤로가기 처리 비활성화
    this.appService.useDefaultBackHandler(false);
    // 루트라면 앱 종료, 아니면 뒤로가기
    this.appService.addBackPressed(() => {
      // 열려있는 다이얼로그가 있다면 마지막 요소 닫기
      const openDialogs = this.dialogService.dialogRefList;
      if (openDialogs?.length) {
        const lastDialog = openDialogs.pop();
        lastDialog?.close();
        return;
      }

      if (this.getCurrentActivatedRoute().snapshot.data['type'] === 'root') {
        if (!this.router.url.includes('home')) {
          this.router.navigate(['home']);
          return;
        }
        this.appService.exit();
      } else {
        if (this.getCurrentActivatedRoute().snapshot.data['handleBack']) {
          return;
        }
        // this.routeHelperService.goBack();
        this.location.back();
      }
    });
  }

  /**
   * ActivatedRoute의 마지막 child를 반환
   */
  private getCurrentActivatedRoute(): ActivatedRoute {
    let route = this.activatedRoute;

    if (!route) {
      throw new Error('ActivatedRoute 없음');
    }

    // firstChild가 없을때까지 탐색
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
