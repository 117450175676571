import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { IBrand, IBrandOktaMember } from 'src/lib/repository/brand/brand.model';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { Utils } from 'src/lib/utils';

@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.scss'],
})
export class TemplateFooterComponent implements OnInit {
  @Input()
  topScrollBehavior: 'auto' | 'smooth' = 'smooth';

  brandOktaMember: IBrandOktaMember = {};

  logoImgUrl?: string;

  brand?: IBrand;

  footerType?: string;

  footerConfig?: Record<string, any>;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
  ) {}

  ngOnInit(): void {
    this.brand = this.brandService.brand;
    this.brandOktaMember = Utils.getParsedJson(
      this.brand.brandOktaMemberJson!,
      {},
    );
    this.footerType = this.homeScreenService.homeScreen.footerType ?? '1';
    this.footerConfig = Utils.getParsedJson(
      this.homeScreenService.homeScreen.footerConfigJson!,
      {},
    );

    if (this.homeScreenService.homeScreen.homepageLogoImageFileData?.path) {
      this.logoImgUrl =
        this.homeScreenService.homeScreen.homepageLogoImageFileData.path;
    }

    if (this.homeScreenService.homeScreen.homepageLogoImageUrl) {
      this.logoImgUrl = this.homeScreenService.homeScreen.homepageLogoImageUrl;
    }

    if (this.brand.logoImgFileData?.path) {
      this.logoImgUrl = this.brand.logoImgFileData.path;
    }

    if (this.brand.logoImgUrl) {
      this.logoImgUrl = this.brand.logoImgUrl;
    }
  }

  onTopButtonClick(): void {
    // <html> 요소 scroll 변경
    this.document.documentElement.scrollTo({
      top: 0,
      behavior: this.topScrollBehavior,
    });
  }
}
