<router-outlet />

<div class="dialog-wrapper">
  @for (dialogRef of dialogService.dialogRefList; track dialogRef.id) {
    <ng-template
      [ngComponentOutlet]="dialogRef.type"
      [ngComponentOutletInputs]="{ dialogRef: dialogRef }"
    ></ng-template>
  }
</div>
