<form class="row g-3 mb-4 needs-validation" [formGroup]="formGroup">
  <!-- 영문 성 -->
  <div class="col-6 col-md-4">
    <label for="Surname" class="form-label required">
      {{ 'Eng Last Name' | translate }}
    </label>

    <input
      validator
      type="text"
      class="form-control"
      formControlName="Surname"
    />
  </div>

  <!-- 영문 명 -->
  <div class="col-6 col-md-4">
    <label for="FirstName" class="form-label required">
      {{ 'Eng First Name' | translate }}
    </label>

    <input
      validator
      type="text"
      class="form-control"
      formControlName="FirstName"
    />
  </div>

  <!-- 생년월일 -->
  <div class="col-6 col-md-4">
    <label for="birthDt" class="form-label required">
      {{ 'birthDt' | translate }}
    </label>

    <input
      validator
      type="date"
      class="form-control"
      formControlName="birthDt"
    />
  </div>

  <!-- 전화번호 -->
  <div class="col-6 col-md-4">
    <label for="TelNum" class="form-label required">
      {{ 'telNum' | translate }}
    </label>

    <input
      validator
      type="text"
      class="form-control"
      formControlName="TelNum"
    />
  </div>

  <!-- 이메일 -->
  <div class="col-6 col-md-4">
    <label for="MailAddress" class="form-label required">
      {{ 'email' | translate }}
    </label>

    <input
      validator
      type="text"
      class="form-control"
      formControlName="MailAddress"
    />
  </div>

  <!-- 체크인 예정 시간 -->
  <div class="col-6 col-md-4">
    <label for="inputState" class="form-label required">
      {{ 'expectedCheckIn' | translate }}
    </label>

    <select validator class="form-select" formControlName="CheckInTime">
      <option value="">
        {{ 'notSelected' | translate }}
      </option>

      @for (checkInTime of checkInTimeList; track $index) {
        <option [value]="checkInTime">
          {{ checkInTime }}
        </option>
      }
    </select>
  </div>
</form>

<!-- 테마이라즈 qna 컨테이너 -->
<!-- @if (questionList.length) {
  <app-tm-question-container
    [questions]="questionList"
    (init)="onTmQuestionContainerInit($event)"
  ></app-tm-question-container>
} -->
