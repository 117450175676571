<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'cancelRsrv' | translate }}
      </h5>

      <button class="btn-close" type="button" (click)="onCloseClick()"></button>
    </div>

    <div class="modal-body">
      <h6>
        {{ 'cancelRule' | translate }}
      </h6>

      <p>
        <small class="text-body-secondary">
          {{ 'refundText' | translate }}
        </small>
      </p>

      <div class="mb-5">
        <app-tm-cancel-policy-table></app-tm-cancel-policy-table>
      </div>

      <table class="table table-borderless mb-5">
        <tbody class="fs-0875 fs-md-6">
          <tr>
            <th class="w-25">
              {{ 'sum' | translate }}
            </th>

            <td>
              {{ tmBooking.TotalPrice | currency: 'JPY' }}
            </td>
          </tr>

          <tr>
            <th class="w-25">
              {{ 'cancelFee' | translate }}
            </th>

            <td>
              {{ cancelFee | currency: 'JPY' }}
            </td>
          </tr>

          <tr>
            <th class="w-25">
              {{ 'refundAmt' | translate }}
            </th>

            <td>
              {{ tmBooking.TotalPrice - cancelFee | currency: 'JPY' }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 취소 가이드 -->
      <p>
        {{ 'cancelGuide' | translate }}
      </p>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-outline-secondary btn-lg"
        (click)="onConfirmClick()"
      >
        {{ 'cancelRsrv' | translate }}
      </button>
    </div>
  </div>
</div>
