import { Component, OnInit } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { AuthService } from '../../../lib/auth/auth.service';
import { UserCardApiService } from '../../../lib/repository/user-card/user-card.api';
import { IUserCard } from '../../../lib/repository/user-card/user-card.model';
import { IUserInfo } from '../../../lib/repository/user-info/user-info.model';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-template-layout',
  templateUrl: './template-layout.component.html',
  styleUrls: ['./template-layout.component.scss'],
})
export class TemplateLayoutComponent implements OnInit {
  // 언어 변경 여부
  canChangeLanguage = this.homeScreenService.homeScreen.languageButtonVisible;

  /**
   * 회원 정보
   */
  userInfo: IUserInfo | null = null;

  /**
   * 포인트 조회용 회원 카드
   */
  userCard: IUserCard | null = null;

  private userCard$: Observable<IUserCard> | null = null;

  constructor(
    private authService: AuthService,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
    private uiService: UiService,
    private userCardApi: UserCardApiService,
  ) {}

  ngOnInit(): void {
    // 홈페이지 아이콘 및 제목 업데이트
    this.uiService.updateDocumentTitle(
      this.homeScreenService.homeScreen.homepageTitle ??
        this.brandService.brand.brandNm,
    );
    this.uiService.updateDocumentIcon(
      this.homeScreenService.homeScreen.homepageFaviconImageUrl ??
        this.brandService.brand.logoImgUrl,
    );

    this.setUserInfo();
  }

  /**
   * 회원 정보 갱신
   */
  onRefreshInfoClick(): void {
    this.userCard$ = null;
    this.authService.refreshAccount$().subscribe();
  }

  /**
   * 회원 정보 설정
   */
  private setUserInfo(): void {
    if (this.authService.account) {
      const { userInfo } = this.authService.account;
      this.userInfo = userInfo;

      // 포인트 사용 가능 브랜드면 조회
      if (this.brandService.isAvail('userInfo_point')) {
        this.setUserCard(userInfo);
      }
    }

    this.authService.loginSubject$
      .asObservable()
      .pipe(
        map((account) => {
          return account?.userInfo;
        }),
        tap((userInfo) => {
          this.userInfo = userInfo;

          // 포인트 사용 가능 브랜드면 조회
          if (this.brandService.isAvail('userInfo_point')) {
            this.setUserCard(userInfo);
          }
        }),
      )
      .subscribe();
  }

  /**
   * 포인트 사용하는 브랜드라면 포인트 조회
   */
  private setUserCard(userInfo: IUserInfo): void {
    if (this.userCard$) {
      return;
    }

    if (userInfo?.userCard?.id) {
      this.userCardApi
        .findItem(userInfo?.userCard?.id)
        .pipe(
          tap((userCard) => {
            this.userCard = userCard;
          }),
        )
        .subscribe();
    }
  }
}
