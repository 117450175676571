import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DrawerModule } from '../drawer/drawer.module';
import { LoadingComponent } from '../loading/loading.component';
import { SharedModule } from '../shared/shared.module';
import { TemplateBannerModule } from './template-banner/template-banner.module';
import { TemplateContentModule } from './template-content/template-content.module';
import { TemplateFooterModule } from './template-footer/template-footer.module';
import { TemplateHeaderModule } from './template-header/template-header.module';
import { TemplateLayoutComponent } from './template-layout.component';

@NgModule({
  declarations: [TemplateLayoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    TemplateFooterModule,
    TemplateHeaderModule,
    TemplateBannerModule,
    TemplateContentModule,
    DrawerModule,
    LoadingComponent,
  ],
  exports: [TemplateLayoutComponent],
})
export class TemplateLayoutModule {}
