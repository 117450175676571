import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Carousel } from 'bootstrap';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';
import { SharedModule } from '../shared/shared.module';

/**
 * 이미지 뷰어 모달 데이터
 */
export interface ImgViewerModalData {
  /**
   * 이미지 인덱스
   *
   * 해당 인덱스의 이미지가 가장 먼저 표시됨
   */
  index: number;

  /**
   * 이미지 URL 목록
   */
  imgUrlList: string[];
}

/**
 * 이미지 뷰어 컴포넌트
 *
 * 이미지가 여러장일때 선택한 이미지를 크게 보여주고, 이미지 목록 표시
 */
@Component({
  selector: 'trnty-img-viewer-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './img-viewer-modal.component.html',
  styleUrl: './img-viewer-modal.component.scss',
})
export class ImgViewerModalComponent
  extends DialogAbstract
  implements AfterViewInit
{
  override dialogRef!: DialogRef<this, void, ImgViewerModalData>;

  /**
   * 현재 이미지 인덱스
   */
  currentIndex: number = 0;

  /**
   * 부트스트랩 캐러셀
   */
  carousel!: Carousel;

  /**
   * 부트스트랩 캐러셀 레퍼런스
   */
  @ViewChild('mainImgCarousel') mainImgCarousel!: ElementRef<HTMLDivElement>;

  constructor(protected override elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.currentIndex = this.dialogRef.data!.index;
  }

  ngAfterViewInit(): void {
    this.carousel = new Carousel(this.mainImgCarousel.nativeElement);
    // 캐러셀 인덱스 변경시 이벤트
    this.mainImgCarousel.nativeElement.addEventListener(
      'slide.bs.carousel',
      ({ to }: any) => {
        this.currentIndex = to;
      },
    );
  }

  /**
   * 닫기 클릭시
   */
  onCloseClick(): void {
    this.dialogRef.close();
  }

  /**
   * 이미지 클릭시
   */
  onImgClick(index: number): void {
    this.currentIndex = index;
    this.carousel.to(index);
  }
}
