import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { TemplateFooterComponent } from './template-footer.component';

@NgModule({
  declarations: [TemplateFooterComponent],
  imports: [CommonModule, SharedModule, FormsModule, RouterModule],
  exports: [TemplateFooterComponent],
})
export class TemplateFooterModule {}
