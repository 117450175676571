<div class="data-wrapper" [class.opacity-25]="isDisabled">
  <!-- 일자 -->
  <div class="date d-flex justify-content-center align-items-end">
    {{ date | date: 'd' }}
  </div>

  <div class="data d-flex justify-content-center align-items-start">
    <!-- 가격 -->
    {{
      !totalPrice || totalPrice < 0
        ? 0
        : (totalPrice / 10000 | number: '1.1-1') + '~'
    }}
  </div>
</div>
