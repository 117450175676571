import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Toast } from 'bootstrap';
import { Observable, fromEvent, shareReplay, tap } from 'rxjs';
import { ToastService } from './toast.service';

export interface ToastOptions extends Toast.Options {
  bodyClassNameList?: string[];

  /**
   * Container 클래스 이름 목록
   *
   * 여백, 좌표 설정에 사용
   */
  containerClassNameList: string[];
}

/**
 * Toast 기본 옵션
 */
const DEFALUT_OPTIONS: ToastOptions = {
  containerClassNameList: ['bottom-20', 'start-50'],
  animation: true,
  autohide: true,
  delay: 3000,
};

/**
 * Bootstrap Toast 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class ToastAppService extends ToastService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
  ) {
    super();
  }

  /**
   * Toast 표시
   *
   * TODO: 표시 스타일 개선
   */
  override showToast(
    message: string,
    action?: string | null,
    option: Partial<ToastOptions> = DEFALUT_OPTIONS,
  ): Observable<any> {
    const combinedOption = { ...DEFALUT_OPTIONS, ...option };

    // FIXME: container 중첩 대응
    // Toast container 생성
    const toastContainer: HTMLDivElement = this.document.createElement('div');
    toastContainer.className = `toast-container position-fixed ${combinedOption?.containerClassNameList?.join(' ') ?? ''}`;

    // Toast 생성
    const toast: HTMLDivElement = this.document.createElement('div');

    if (action) {
      toast.className = 'toast';
    } else {
      toast.className = 'toast pe-none';
    }

    toast.setAttribute('role', 'alert');
    toast.style.setProperty('--bs-toast-max-width', 'auto');

    toastContainer.appendChild(toast);

    // Toast 내용
    const toastBody: HTMLDivElement = this.document.createElement('div');
    toastBody.className = `toast-body d-flex align-items-center ${combinedOption?.bodyClassNameList?.join(' ') ?? ''}`;

    toast.appendChild(toastBody);

    // 텍스트
    const toastText: HTMLSpanElement = this.document.createElement('span');
    toastText.className = 'flex-fill';

    // Action 있으면 오른쪽 공백 추가
    if (action) {
      toastText.style.marginRight = '8px';
    }
    toastText.innerHTML = this.translateService.instant(message);

    toastBody.appendChild(toastText);

    let actionButton: HTMLButtonElement | null = null;

    // Action 있으면 버튼 생성
    if (action) {
      actionButton = this.document.createElement('button');
      actionButton.innerHTML = this.translateService.instant(action);
      actionButton.type = 'button';
      actionButton.className = 'btn btn-primary btn-sm';

      // Toast 자동으로 숨기지 않음
      combinedOption.autohide = false;

      toastBody.appendChild(actionButton);
    }

    // TODO: Action 여러 개 대응
    // const buttonList: HTMLDivElement = this.document.createElement('div');
    // buttonList.className = 'mt-2 pt-2 border-top';

    // Toast container 를 body 에 추가
    this.document.body.appendChild(toastContainer);

    // Bootstrap Toast 초기화
    const bsToast = Toast.getOrCreateInstance(toast, combinedOption);

    // 표시
    bsToast.show();

    // Toast 숨김 이벤트
    const hiddenObservable = fromEvent(toast, 'hidden.bs.toast').pipe(
      tap(() => {
        // Bootstrap Toast 제거
        bsToast.dispose();

        // Toast container 제거
        this.document.body.removeChild(toastContainer);
      }),
      // 이벤트 중복 동작 방지
      shareReplay(1),
    );

    // 외부에서 구독하지 않아도 이벤트 동작하도록 내부 구독
    hiddenObservable.subscribe();

    // Action 버튼 없으면 숨김 이벤트 반환
    if (!actionButton) {
      return hiddenObservable;
    }

    // Action 버튼 클릭 이벤트
    const actionClickObservable = fromEvent(actionButton, 'click').pipe(
      tap(() => {
        // Toast 숨김
        bsToast.hide();
      }),
      // 이벤트 중복 동작 방지
      shareReplay(1),
    );

    // 외부에서 구독하지 않아도 이벤트 동작하도록 내부 구독
    actionClickObservable.subscribe();

    // Action 버튼 클릭 이벤트 반환
    return actionClickObservable;
  }
}
