@if (cancelPolicy.NonRefundable === 0) {
  <table class="table table-borderless mb-5">
    <tbody class="fs-0875 fs-md-6">
      @for (AdditionalCase of sortedAdditionalCases; track $index) {
        <tr>
          <th class="w-25">
            {{
              'cancelPolicyBeforeDays'
                | translate
                  : {
                      start: AdditionalCase.StartDays,
                      end: AdditionalCase.EndDays
                    }
            }}
          </th>

          <td>
            {{ 'cancelPolicyRate' | translate: { rate: AdditionalCase.Rate } }}
          </td>
        </tr>
      }

      <tr>
        <th class="w-25">
          {{ 'refundText5' | translate }}
        </th>

        <td>
          {{
            'cancelPolicyRate'
              | translate: { rate: cancelPolicy.CaseOfCancellationToday.Rate }
          }}
        </td>
      </tr>

      <tr>
        <th class="w-25">
          {{ 'refundText7' | translate }}
        </th>

        <td>
          {{
            'cancelPolicyRate'
              | translate: { rate: cancelPolicy.CaseOfNoShow.Rate }
          }}
        </td>
      </tr>
    </tbody>
  </table>
} @else {
  <div>
    {{ 'cancelPolicyNonRefundable' | translate }}
  </div>
}
