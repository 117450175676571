import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { IBrand } from '../brand/brand.model';
import { IHomeScreen } from './home-screen.model';

@Injectable({
  providedIn: 'root',
})
export class HomepageApiService extends PageRepositoryService<any> {
  protected override baseUri = 'api/homepage';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getBrand(): Observable<IBrand> {
    this.isDetailLoading = true;

    return this.http
      .get<IBrand>(`${this.apiServerUrl}/${this.baseUri}/brand`)
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }

  getHomeScreen(brandId: number): Observable<IHomeScreen> {
    this.isDetailLoading = true;

    return this.http
      .get<IHomeScreen>(`${this.apiServerUrl}/${this.baseUri}/homeScreen`, {
        params: { brandId },
        responseType: 'json',
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }
}
