/* eslint-disable no-console */
import {
  ApplicationRef,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Observable, concat, interval, of } from 'rxjs';
import { filter, first, mergeMap, tap } from 'rxjs/operators';

/**
 * 앱 업데이트 옵션
 */
export interface PwUpdateOption {
  /**
   * 업데이트 확인 메시지 텍스트 번역 키
   */
  messageTranslateKey?: string;

  /**
   * 업데이트 확인 버튼 텍스트 번역 키
   */
  actionTranslateKey?: string;

  /**
   * 업데이트 확인 주기(밀리초)
   * @default 10000ms
   */
  updateCheckInterval?: number;
}

/**
 * 앱 업데이트 옵션 인젝션 토큰
 */
export const PW_UPDATE_OPTION = new InjectionToken<PwUpdateOption>(
  '업데이트 확인 메시지 내용, 버튼 텍스트',
);

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private messageTranslateKey?: string;

  private actionTranslateKey?: string;

  private updateCheckInterval: number;

  onUpdateAvailableMessage: (
    message: string,
    action: string,
  ) => Observable<void> = () => of(undefined);

  constructor(
    private swUpdate: SwUpdate,
    private applicationRef: ApplicationRef,
    private translateService: TranslateService,
    @Optional()
    @Inject(PW_UPDATE_OPTION)
    private pwUpdateOption: PwUpdateOption,
  ) {
    this.messageTranslateKey = this.pwUpdateOption?.messageTranslateKey;
    this.actionTranslateKey = this.pwUpdateOption?.actionTranslateKey;
    this.updateCheckInterval =
      !this.pwUpdateOption?.updateCheckInterval ||
      this.pwUpdateOption?.updateCheckInterval < 1
        ? 30 * 1000
        : this.pwUpdateOption.updateCheckInterval;
    this.initUpdate();
  }

  /**
   * 새 버전이 있다면 업데이트
   */
  initUpdate(): void {
    if (!('serviceWorker' in navigator)) {
      // 서비스워커 없으면 종료
      console.log('service worker is not ready');
      return;
    }

    if (!this.swUpdate.isEnabled) {
      // 서비스워커 사용하지 않으면 종료
      console.log('service worker is not enable');
      return;
    }

    concat(
      // 앱 상태 stable일때
      this.applicationRef.isStable.pipe(first((isStable) => isStable === true)),
      // 매 주기마다
      interval(this.updateCheckInterval),
    )
      // 업데이트 확인
      .pipe(tap(() => this.swUpdate.checkForUpdate().then()))
      .subscribe();

    // 버전 감지
    this.swUpdate.versionUpdates
      .pipe(
        // 업데이트 가능하면
        filter((e) => e.type === 'VERSION_READY'),
        // 새로고침 여부 묻고
        mergeMap(() => this.askUpdate$()),
        // 업데이트
        mergeMap(() => this.swUpdate.activateUpdate()),
        tap(() => document.location.reload()),
      )
      .subscribe();

    // 이용 불가능한 버전이면
    this.swUpdate.unrecoverable
      .pipe(
        // 업데이트
        // mergeMap(() => this.swUpdate.activateUpdate()),
        tap(() => document.location.reload()),
      )
      .subscribe();
  }

  /**
   * 업데이트 진행 여부 확인
   */
  askUpdate$(): Observable<void> {
    const message = this.messageTranslateKey
      ? this.translateService.instant(this.messageTranslateKey)
      : 'New version available';
    const action = this.actionTranslateKey
      ? this.translateService.instant(this.actionTranslateKey)
      : 'Update';

    return this.onUpdateAvailableMessage(message, action);
  }
}
