import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { TemplateHeaderComponent } from './template-header.component';

@NgModule({
  declarations: [TemplateHeaderComponent],
  imports: [CommonModule, FormsModule, RouterModule, SharedModule],
  exports: [TemplateHeaderComponent],
})
export class TemplateHeaderModule {}
