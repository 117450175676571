import { Type } from '@angular/core';
import { Modal } from 'bootstrap';
import { DialogRef, IDialogRef } from './dialog-ref';

/**
 * Dialog Reference
 *
 * @param T Component type
 * @param R Return type
 * @param D Input data type
 */
export class DialogRefApp<T, R = any, D = any> extends DialogRef<T, R, D> {
  override modal?: Modal;

  constructor(type: Type<T>, options?: Modal.Options, data?: D) {
    super(type, options, data);
  }

  /**
   * Dialog 토글
   */
  override toggle(relatedTarget?: IDialogRef<any>): void {
    if (relatedTarget) {
      const { element } = relatedTarget as DialogRef<any>;
      this.modal?.toggle(element);
      return;
    }

    this.modal?.toggle();
  }

  /**
   * Dialog 닫기
   */
  override close(result?: R): void {
    this.result = result;
    this.modal?.hide();

    // Modal 생성되지 않음
    if (!this.modal) {
      this.onDialogClosing.next(this.result);
      this.onDialogClosing.complete();

      this.onDialogClosed.next(this.result);
      this.onDialogClosed.complete();
    }
  }
}
