// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 시스템 padding
 */
export interface SystemPadding {
  /**
   * 죄측 padding
   */
  left: number;

  /**
   * 상단 padding
   */
  top: number;

  /**
   * 우측 padding
   */
  right: number;

  /**
   * 하단 padding
   */
  bottom: number;
}

/**
 * 앱 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  private systemPadding?: SystemPadding;

  private windowResized: () => void;

  constructor(private flutterBridge: FlutterBridgeService) {
    this.windowResized = () => {
      this.systemPadding = null!;
    };
    this.addWindowResized(this.windowResized);
  }

  /**
   * 뒤로가기 이벤트 추가
   *
   * @param callback 처리할 작업
   */
  addBackPressed(callback: () => void): void {
    window.addEventListener('backPressed', callback);
    this.useDefaultBackHandler(false);
  }

  /**
   * 뒤로가기 이벤트 삭제
   *
   * @param callback 삭제할 작업
   */
  removeBackPressed(callback: () => void): void {
    window.removeEventListener('backPressed', callback);
  }

  /**
   * 기본 뒤로가기 처리 사용 설정
   *
   * @default true
   * @param use 사용 여부
   */
  useDefaultBackHandler(use: boolean): void {
    this.flutterBridge.callHandler('defaultBackHandler', use);
  }

  addWindowResized(callback: () => void): void {
    window.addEventListener('windowResized', callback);
  }

  removeWindowResized(callback: () => void): void {
    window.removeEventListener('windowResized', callback);
  }

  /**
   * SafeArea 사용 설정
   *
   * @default true
   * @param use 사용 여부
   */
  useSafeArea(use: boolean): void {
    this.flutterBridge.callHandler('safeArea', use);
  }

  /**
   * 시스템 Padding 조회
   */
  async getSystemPadding(): Promise<SystemPadding> {
    if (this.systemPadding) return this.systemPadding;
    return this.flutterBridge
      .callHandler<SystemPadding>('systemPadding')
      .then((value) => {
        this.systemPadding = value;
        return value;
      });
  }

  /**
   * View Insets 조회
   */
  async getViewInsets(): Promise<SystemPadding> {
    return this.flutterBridge.callHandler('getViewInsets');
  }

  /**
   * 기기 가로/세로 여부 조회
   */
  async getDeviceOrientation(): Promise<'portrait' | 'landscape'> {
    return this.flutterBridge.callHandler('orientation');
  }

  /**
   * 뒤로가기를 통한 앱 종료 가능 설정
   *
   * @default true
   * @param canExit 가능 여부
   */
  setCanExit(canExit: boolean): void {
    this.flutterBridge.callHandler('canExit', canExit);
  }

  /**
   * 앱 버전 조회
   */
  get version(): Promise<string> {
    return this.flutterBridge.callHandler('version');
  }

  /**
   * 앱 종료
   *
   * Android 전용, Apple은 Human Interface 가이드라인에 의해 구현되지 않음
   */
  exit(): void {
    this.flutterBridge.callHandler('exit');
  }
}
