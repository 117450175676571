<!-- 이미지 개수별로 다른 클래스 부여, 그리드 열 조절 -->
<div
  class="img-container d-grid gap-2"
  [class.imgs-5]="imgUrlIndexList.length === 5"
  [class.imgs-7]="imgUrlIndexList.length === 7"
>
  @for (
    imgUrlIndex of imgUrlIndexList;
    track imgUrlIndex;
    let i = $index, first = $first, last = $last
  ) {
    @if (imgUrlList[imgUrlIndex]) {
      <!-- 첫번째 이미지는 메인 이미지로 다른 이미지보다 큼 -->
      <div
        role="button"
        class="ratio ratio-16x9 rounded overflow-hidden"
        [class.main-img]="first"
        (click)="onImgClick(i)"
      >
        <img
          class="w-100 h-100 object-fit-cover"
          [src]="imgUrlList[imgUrlIndex]"
        />

        <!-- 이미지가 그리드 칸 수보다 많으면 더보기 오버레이 추가 -->
        @if (last && imgUrlIndexList.length < imgUrlList.length) {
          <div
            class="align-content-center bg-black bg-opacity-75 position-absolute text-center text-white"
          >
            <i class="bi bi-camera"></i>
            <br />
            <span> {{ 'viewMore' | translate }} </span>
          </div>
        }
      </div>
    }
  }
</div>
