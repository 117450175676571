<div class="drawer-wrapper">
  <div class="offcanvas-header user-info-wrapper gap-1">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
    ></button>

    <div class="flex-grow-1"></div>

    <ng-container *ngIf="userInfo; else notLogin">
      <span>{{ userInfo.userNm }}{{ 'sir' | translate }}</span>

      <!-- 잔여 포인트 -->
      <ng-container *feature="'userInfo_point'">
        <span class="point">{{ userCard?.prpayAmt | number }}P</span>
        <!-- 정보 갱신 버튼 -->
        <button
          type="button"
          class="btn refresh"
          (click)="onRefreshInfoButtonClick()"
        >
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </ng-container>
    </ng-container>

    <ng-template #notLogin
      ><span>{{ 'key-31' | translate }}</span></ng-template
    >
  </div>

  <div class="login-wrapper">
    <ng-container *ngIf="userInfo; else notLogin2">
      <div
        class="login-button"
        (click)="onLogoutClick()"
        data-bs-dismiss="offcanvas"
      >
        {{ 'logout' | translate }}
      </div>
      <div
        class="login-button"
        routerLink="/my/change-info"
        data-bs-dismiss="offcanvas"
      >
        {{ 'changeUserInfo' | translate }}
      </div>
    </ng-container>

    <ng-template #notLogin2>
      <div
        class="login-button"
        (click)="onLoginClick()"
        data-bs-dismiss="offcanvas"
      >
        {{ 'login' | translate }}
      </div>
      <div
        class="login-button"
        *ngIf="canRegister"
        (click)="onRegisterClick()"
        data-bs-dismiss="offcanvas"
      >
        {{ 'key-32' | translate }}
      </div>
    </ng-template>
  </div>

  <ng-container *homeScreen="'showMembershipMenuFlg'">
    <ng-container *feature="'userInfo_point'">
      <div class="login-wrapper">
        <div
          class="login-button"
          routerLink="/my/prpay"
          data-bs-dismiss="offcanvas"
        >
          {{ 'Membership' | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="booking-list-menu"
    routerLink="/booking"
    data-bs-dismiss="offcanvas"
  >
    {{ 'rsrvHist' | translate }}
  </div>

  <div class="menu-container">
    <ng-container *ngFor="let menu of menuListNew">
      <ng-container
        *ngIf="
          isMenuVisibleByBreakpoint(menu.breakpointToShow) &&
          menu.type !== 'notice'
        "
      >
        <!-- 메뉴 타입 'URL'일때 동작 -->
        <ng-container *ngIf="menu.type === 'url'; else defaultMenu">
          <!-- URL 주소가 '/'으로 시작하면 내부 주소로 판단 -->
          <ng-container
            *ngIf="menu.url?.startsWith('/'); else externalLinkMenu"
          >
            <!-- 내부 링크 -->
            <div
              class="menu-wrapper"
              routerLinkActive="on"
              [routerLink]="menu.url"
              data-bs-dismiss="offcanvas"
            >
              {{ menu.title! | translate }}

              <i class="bi bi-chevron-right menu-icon"></i>
            </div>
          </ng-container>
          <ng-template #externalLinkMenu>
            <!-- 외부 링크 -->
            <a target="_blank" [href]="menu.url">
              <div class="menu-wrapper" data-bs-dismiss="offcanvas">
                {{ menu.title! | translate }}

                <i class="bi bi-box-arrow-up-right menu-icon"></i>
              </div>
            </a>
          </ng-template>
        </ng-container>
        <ng-template #defaultMenu>
          <!-- 'URL'외 타입들은 메뉴 페이지에서 처리 -->
          <div
            class="menu-wrapper"
            routerLinkActive="on"
            queryParamsHandling="merge"
            [routerLink]="['menu', menu.id]"
            data-bs-dismiss="offcanvas"
          >
            {{ menu.title! | translate }}

            <i class="bi bi-chevron-right menu-icon"></i>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <!-- 내부 링크 -->
    <div
      class="menu-wrapper"
      routerLinkActive="on"
      routerLink="/cs/notice"
      data-bs-dismiss="offcanvas"
    >
      {{ 'notice' | translate }}

      <i class="bi bi-chevron-right menu-icon"></i>
    </div>
  </div>
</div>
