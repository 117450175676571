import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * FIXME: 재구현
 */
@Component({
  selector: 'app-template-banner',
  templateUrl: './template-banner.component.html',
  styleUrls: ['./template-banner.component.scss'],
})
export class TemplateBannerComponent implements OnInit {
  constructor(public router: Router) {}

  // 메뉴리스트
  // readonly menuList = this.configService.configurations.menuList;

  ngOnInit(): void {}

  getBaseMenu() {
    const routerLink = `/${this.router.url.split('/')[1]}`;
    // return this.menu.MENU_LIST.find((menu) => {
    //   return menu.routerLink === routerLink;
    // });
    return routerLink;
  }

  getMenuBackgroundUrl(): string {
    let path = '/assets/imgs/sub_layout.jpeg';
    // const { backgroundUrl } = this.getBaseMenu();
    // if (backgroundUrl) {
    //   path = backgroundUrl;
    // }
    return `url(${path})`;
  }
}
