import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  AdditionalCase,
  CancelPolicy,
} from 'src/lib/repository/temairazu/tm-plan.model';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'app-tm-cancel-policy-table',
  templateUrl: './tm-cancel-policy-table.component.html',
  styleUrls: ['./tm-cancel-policy-table.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class TmCancelPolicyTableComponent implements OnInit {
  /**
   * 정렬된 추가 취소 규정 목록
   */
  sortedAdditionalCases!: AdditionalCase[];

  /**
   * 테마이라즈 예약 취소 규정
   */
  @Input() cancelPolicy!: CancelPolicy;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  constructor() {}

  ngOnInit(): void {
    this.cancelPolicy = this.cancelPolicy || this.getDefaultCancelPolicy();

    this.sortedAdditionalCases = this.getSortedAdditionalCases(
      this.cancelPolicy.AdditionalCases,
    );
  }

  /**
   * 기본 취소 규정 획득
   */
  private getDefaultCancelPolicy(): CancelPolicy {
    const defaultCancelPolicy = {
      NonRefundable: 0,
      Deposit: 0,
      CaseOfNoShow: { Rate: 100 },
      CaseOfCancellationToday: { Rate: 100 },
      AdditionalCases: [
        { StartDays: 7, EndDays: 3, Rate: 50 },
        { StartDays: 2, EndDays: 1, Rate: 70 },
      ],
    };

    return defaultCancelPolicy;
  }

  /**
   * 추가 취소 규정 획득
   */
  private getSortedAdditionalCases(
    unsorted: AdditionalCase[],
  ): AdditionalCase[] {
    const sorted = unsorted.sort((a, b) => b.StartDays - a.StartDays);

    return sorted;
  }
}
