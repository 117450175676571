<div class="container py-5">
  @if (hotel) {
    <h3>
      {{ hotel.Name }}
    </h3>

    <!-- 주소, 전화번호는 일부러 번역 안함 -->
    <p class="text-body-secondary">
      <i class="bi bi-geo-alt"></i>

      <span translate="no">
        {{ hotel.Address }}
      </span>

      <br />

      <i class="bi bi-telephone"></i>

      <span translate="no">
        {{ hotel.PhoneNum }}
      </span>
    </p>

    <div class="mb-3">
      <trnty-img-grid [imgUrlList]="imgUrlList"></trnty-img-grid>
    </div>

    <p class="keep-line mb-4">{{ hotel.CatchCopy }}</p>

    <p class="keep-line mb-4">{{ mrhstDirections || hotel.AccessOverview }}</p>
  }

  <h4>
    {{ 'selectCondition' | translate }}
  </h4>

  <div class="mb-5">
    <trnty-hotel-goods-search
      [hasCheckInDt]="false"
      [hasTourRegionCode]="false"
      [hasRoomCnt]="true"
      [hasReset]="false"
      (init)="onSearchInit($event)"
      (searchClick)="onSearchClick()"
    ></trnty-hotel-goods-search>
  </div>

  <h4>
    {{ 'packagegoods.selectStartDate' | translate }}
  </h4>

  <div class="mb-5">
    <trnty-booking-calendar-year-month-selector
      class="d-block mb-3"
      (init)="onYearMonthSelectorInit($event)"
      (yearMonthChange)="onYearMonthChange($event)"
    ></trnty-booking-calendar-year-month-selector>

    <trnty-booking-calendar-type1
      [dateList]="dateList"
      [calendarDataComponent]="calendarDataComponent"
      [componentData]="componentData"
      (init)="onCalendarInit($event)"
      (dateClick)="onCalendarDateClick($event)"
    ></trnty-booking-calendar-type1>
  </div>

  <h4>
    {{ 'selectRoom' | translate }}
  </h4>

  <ul class="list-group list-group-flush mb-5">
    @for (tmRoom of roomList; track $index) {
      <li class="list-group-item px-0 py-3">
        <div class="row g-3">
          <div class="col-12 col-md-4 col-lg-3">
            <figure
              class="ratio ratio-16x9 m-0"
              role="button"
              (click)="onRoomImgClick(0, tmRoom.Pictures)"
            >
              <img [src]="tmRoom.Pictures[0].Url" class="rounded" />
            </figure>
          </div>

          <div class="col-12 col-md">
            <small>
              {{ tmRoom | tmEnum: 'RoomCategory' }}
            </small>

            <small>
              {{ tmRoom | tmEnum: 'RoomType' : { exclude: [8] } }}
            </small>

            <h5 [innerHTML]="tmRoom.RoomName"></h5>

            <p class="m-0">
              {{ tmRoom.RoomDescription }}
            </p>
          </div>

          @if (roomInventoryMap.get(tmRoom.RoomId); as roomInventory) {
            <div
              class="d-flex col-12 col-md-2 text-end flex-md-column justify-content-between"
              translate="no"
            >
              @if (roomInventory.Inventory) {
                <span class="fs-2 text-primary">
                  ¥{{ roomInventory.MinTotalPrice | number }}~
                </span>

                <div class="col col-md-12 text-end">
                  <button
                    class="btn btn-primary my-auto"
                    (click)="onRoomSelectClick(tmRoom.RoomId)"
                  >
                    {{ 'BUTTON.makeBooking' | translate }}
                  </button>
                </div>
              } @else {
                <span class="fs-2 text-body-tertiary">
                  {{ 'soldOut' | translate }}
                </span>
              }
            </div>
          }
        </div>
      </li>
    }
  </ul>
</div>
