import {
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Popover,
  Tooltip,
} from 'bootstrap';
import BaseComponent from 'bootstrap/js/dist/base-component';
import { BootstrapService } from './bootstrap.service';

export class BootstrapAppService extends BootstrapService {
  override getCarousel(
    element: string | Element,
    opt?: Carousel.Options,
  ): Carousel {
    return Carousel.getOrCreateInstance(element, opt);
  }

  override getCollapse(
    element: string | Element,
    opt?: Collapse.Options,
  ): Collapse {
    return Collapse.getOrCreateInstance(element, opt);
  }

  override getModal(element: string | Element, opt?: Modal.Options): Modal {
    return Modal.getOrCreateInstance(element, opt ?? Modal.Default);
  }

  override getBootstrapClassByName(name: string): typeof BaseComponent | null {
    if (name === 'Button') {
      return Button;
    }
    if (name === 'Dropdown') {
      return Dropdown;
    }
    if (name === 'Tooltip') {
      return Tooltip;
    }
    if (name === 'Popover') {
      return Popover;
    }
    if (name === 'Modal') {
      return Modal;
    }

    return null;
  }
}
