import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import dayjs from 'dayjs';
import { AbstractForm } from 'src/lib/components/abstract/abstract.form';
import { TmPlan } from 'src/lib/repository/temairazu/tm-plan.model';
import { IUserInfo } from 'src/lib/repository/user-info/user-info.model';
import { SharedModule } from '../../shared/shared.module';

/**
 * 테마이라즈 예약 폼 컴포넌트
 */
@Component({
  selector: 'trnty-tm-mrhst-booking-form',
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule,
    // TmQuestionContainerModule,
  ],
  templateUrl: './tm-mrhst-booking-form.component.html',
  styleUrl: './tm-mrhst-booking-form.component.scss',
})
export class TmMrhstBookingFormComponent
  extends AbstractForm
  implements OnChanges
{
  override formGroup: FormGroup = new FormGroup({
    CheckInTime: new FormControl<string>('', [Validators.required]),
    Surname: new FormControl<string>(null!, [Validators.required]),
    FirstName: new FormControl<string>(null!, [Validators.required]),
    TelNum: new FormControl<string>(null!, [Validators.required]),
    MailAddress: new FormControl<string>(null!, [Validators.required]),
    birthDt: new FormControl<number>(null!, [Validators.required]),
  });

  // tmQuestionContainer!: TmQuestionContainerComponent;

  /**
   * 테마이라즈 플랜 체크인 시간 목록
   */
  checkInTimeList: string[] = [];

  // questionList: Question[] = [];

  /**
   * 테마이라즈 플랜
   */
  @Input() plan!: TmPlan;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  constructor() {
    super();
  }

  ngOnChanges({ plan }: SimpleChanges): void {
    if (plan.currentValue) {
      this.setCheckInTimeList();

      // this.questionList = [
      //   ...this.plan.Questions,
      //   {
      //     Contents: '備考',
      //     Type: 'text',
      //     Size: 100,
      //     Required: 0,
      //   },
      // ];
    }
  }

  override getValue(): any {
    const { Surname, FirstName, birthDt, ...rest } =
      this.formGroup.getRawValue();

    const value = {
      Surname,
      FirstName,
      SurnameKana: Surname,
      FirstNameKana: FirstName,
      // QuestionsAns: this.tmQuestionContainer.getQnaList(),
      BirthdayYear: dayjs(birthDt).year(),
      BirthdayMonth: dayjs(birthDt).month() + 1,
      BirthdayDay: dayjs(birthDt).date(),
      ...rest,
    };

    return value;
  }

  override setValue(value: IUserInfo): void {
    const { firstName, familyName, birthDt, mobileNum, email } = value;

    const patchValue: any = {
      Surname: familyName,
      FirstName: firstName,
      MailAddress: email,
      birthDt,
    };

    if (mobileNum) {
      patchValue.TelNum = this.getOnlyNumber(mobileNum);
    }

    this.formGroup.patchValue(patchValue);
  }

  // onTmQuestionContainerInit(component: TmQuestionContainerComponent): void {
  //   this.tmQuestionContainer = component;
  // }

  /**
   * 입력값중 숫자만 반환
   */
  private getOnlyNumber(data: string): string {
    if (!data) {
      return '';
    }

    // 한글짜식 분리하여 필터
    const filtered = data
      .split('')
      .filter(
        (char) =>
          ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(char) >= 0,
      );

    // 합치기
    return filtered.join('');
  }

  /**
   * 체크인 시간 목록 입력
   */
  private setCheckInTimeList(): void {
    this.checkInTimeList = [];

    // 테마이라즈 플랜의 체크인 시작/종료 시간
    const {
      CheckinTimeStartH,
      CheckinTimeStartM,
      CheckinTimeEndH,
      CheckinTimeEndM,
    } = this.plan;

    const onlyDate = dayjs().hour(0).minute(0).second(0).millisecond(0);
    let startTm = onlyDate.hour(CheckinTimeStartH).minute(CheckinTimeStartM);
    const endTm = onlyDate.hour(CheckinTimeEndH).minute(CheckinTimeEndM);

    while (startTm.valueOf() < endTm.valueOf()) {
      this.checkInTimeList.push(startTm.format('HH:mm'));
      startTm = startTm.add(30, 'minute');
    }

    this.checkInTimeList.push(endTm.format('HH:mm'));
  }
}
