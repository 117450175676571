import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { TemplateBannerComponent } from './template-banner.component';

@NgModule({
  declarations: [TemplateBannerComponent],
  imports: [CommonModule, SharedModule, FormsModule, RouterModule],
  exports: [TemplateBannerComponent],
})
export class TemplateBannerModule {}
