import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { ImgViewerModalComponent } from '../img-viewer-modal/img-viewer-modal.component';
import { SharedModule } from '../shared/shared.module';

/**
 * 이미지 그리드 컴포넌트
 */
@Component({
  selector: 'trnty-img-grid',
  standalone: true,
  imports: [SharedModule, ImgViewerModalComponent],
  templateUrl: './img-grid.component.html',
  styleUrl: './img-grid.component.scss',
})
export class ImgGridComponent implements OnChanges {
  /**
   * 이미지 URL 인덱스 목록
   *
   * 이미지를 첫 5장 또는 7장 표시할때 사용
   */
  imgUrlIndexList!: number[];

  /**
   * 이미지 URL 목록
   */
  @Input() imgUrlList: string[] = [];

  constructor(private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imgUrlList'].currentValue) {
      this.setGrid();
    }
  }

  /**
   * 이미지 클릭시
   */
  onImgClick(index: number): void {
    const data = {
      index,
      imgUrlList: this.imgUrlList,
    };

    this.dialogService.open(ImgViewerModalComponent, data);
  }

  /**
   * 그리드 설정
   */
  private setGrid(): void {
    if (this.imgUrlList.length >= 6) {
      this.imgUrlIndexList = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.imgUrlIndexList = [0, 1, 2, 3, 4];
    }
  }
}
