import { Component, OnInit } from '@angular/core';
import { AbstractCalendarDataComponent } from 'src/lib/components/abstract/abstract-calendar-data.component';
import { TmInventory } from 'src/lib/repository/temairazu/tm-plan.model';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'trnty-temairazu-calendar-data',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './temairazu-calendar-data.component.html',
  styleUrl: './temairazu-calendar-data.component.scss',
})
export class TemairazuCalendarDataComponent
  extends AbstractCalendarDataComponent<TmInventory[]>
  implements OnInit
{
  /**
   * 가격
   */
  totalPrice?: number;

  /**
   * 재고
   */
  remainCnt?: number;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  override setValue(): void {
    const filtered = this.componentData?.filter(
      ({ Date }) => Date === this.date,
    );

    const [first] = filtered || [];

    this.remainCnt = first?.TotalInventory || 0;
    this.totalPrice = first?.MinTotalPrice;
  }

  override setDisabled(): void {
    this.isDisabled = !this.remainCnt || this.remainCnt < 0;
  }
}
