import { Injectable, Type } from '@angular/core';
import { Modal } from 'bootstrap';
import { Observable, tap } from 'rxjs';
import { DialogRef, IDialogRef } from '../components/dialog-ref';
import { DialogRefApp } from '../components/dialog-ref.app';
import { DialogAbstract } from '../components/dialog.abstract';
import {
  AlertDialogComponent,
  AlertDialogRef,
} from '../components/shared/alert-dialog/alert-dialog.component';
import { IAlertDialogData } from '../components/shared/alert-dialog/alert-dialog.data';
import { DialogService } from './dialog.service';

/**
 * 대화창 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class DialogAppService extends DialogService {
  /**
   * Dialog 생성 및 표시
   */
  override open<T extends DialogAbstract, R = any, D = any>(
    component: Type<T>,
    data?: D,
    options?: Partial<Modal.Options>,
  ): IDialogRef<T, R, D> {
    const dialogRef: DialogRef<T, R, D> = new DialogRefApp(component);
    dialogRef.data = data;
    dialogRef.options = options;

    this.dialogRefList.push(dialogRef);

    dialogRef.onDialogClosed
      .asObservable()
      .pipe(
        tap(() => {
          this.dialogRefList.splice(this.dialogRefList.indexOf(dialogRef), 1);
        }),
      )
      .subscribe();

    return dialogRef;
  }

  /**
   * Dialog 생성
   */
  override create<T extends DialogAbstract, R = any, D = any>(
    component: Type<T>,
    data?: D,
    options?: Partial<Modal.Options>,
  ): IDialogRef<T, R, D> {
    const dialogRef: DialogRef<T, R, D> = new DialogRefApp(component);
    dialogRef.data = data;
    dialogRef.options = options;
    dialogRef.hidden = true;

    this.dialogRefList.push(dialogRef);

    return dialogRef;
  }

  override openAlertDialog(
    data: IAlertDialogData,
    options?: Partial<Modal.Options>,
  ): Observable<any> {
    const dialogRef: AlertDialogRef = new DialogRefApp(AlertDialogComponent);
    dialogRef.data = data;
    dialogRef.options = options;

    this.dialogRefList.push(dialogRef);

    return dialogRef.onDialogClosed.asObservable().pipe(
      tap(() => {
        this.dialogRefList.splice(this.dialogRefList.indexOf(dialogRef), 1);
      }),
    );
  }
}
