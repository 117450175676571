<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header" *ngIf="dialogRef.data?.title">
      <h1
        class="modal-title fs-5"
        [innerHTML]="dialogRef.data?.title! | translate | safeHtml"
      ></h1>
    </div>
    <div
      class="modal-body fs-5"
      [innerHTML]="dialogRef.data?.message ?? '' | translate | safeHtml"
    ></div>
    <div class="modal-footer" *ngIf="hasButtonData">
      <button
        class="btn btn-primary"
        type="button"
        *ngIf="dialogRef.data?.positiveButton"
        (click)="onClickPositive()"
      >
        {{ dialogRef.data?.positiveButton?.text ?? 'BUTTON.Ok' | translate }}
      </button>
      <button
        class="btn btn-outline-secondary"
        type="button"
        *ngIf="dialogRef.data?.negativeButton"
        (click)="onClickNegative()"
      >
        {{
          dialogRef.data?.negativeButton?.text ?? 'BUTTON.Cancel' | translate
        }}
      </button>
      <div class="space" *ngIf="dialogRef.data?.neutralButton"></div>
      <button
        class="btn btn-primary-outline"
        type="button"
        *ngIf="dialogRef.data?.neutralButton"
        (click)="onClickNeutral()"
      >
        {{ dialogRef.data?.neutralButton?.text ?? 'BUTTON.Close' | translate }}
      </button>
    </div>
  </div>
</div>
