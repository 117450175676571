<div class="container py-5">
  @if (roomPlanSearch) {
    @if (hotel) {
      <h2 class="link-primary d-inline-block fs-6">
        <a
          [routerLink]="['/tmmrhst', mrhstId]"
          [queryParams]="bookingCondition"
        >
          {{ hotel.Name }}
        </a>
      </h2>
    }

    <h3>
      {{ roomPlanSearch.RoomName }}
    </h3>

    <div class="mb-3">
      <trnty-img-grid [imgUrlList]="imgUrlList"></trnty-img-grid>
    </div>

    <p class="keep-line mb-5">{{ roomPlanSearch.RoomDescription }}</p>

    @if (roomPlanSearch) {
      <h4>{{ 'Room' | translate }}</h4>

      <table class="table table-borderless mb-5">
        <tbody class="fs-0875 fs-md-6">
          <tr>
            <th class="w-25">{{ 'roomType' | translate }}</th>
            <td>
              {{ roomPlanSearch | tmEnum: 'RoomCategory' }}
              {{ roomPlanSearch | tmEnum: 'RoomType' : { exclude: [8] } }}
            </td>
          </tr>

          <tr>
            <th class="w-25">{{ 'Capacity' | translate }}</th>
            <td>
              {{ 'unit.people' | translate: { cnt: roomPlanSearch.MaxPax } }}
            </td>
          </tr>

          <tr>
            <th class="w-25">{{ 'room.equipment' | translate }}</th>
            <td>{{ roomPlanSearch | tmEnum: 'Equipment' }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'room.amenity' | translate }}</th>
            <td>{{ roomPlanSearch | tmEnum: 'Amenity' }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'etc' | translate }}</th>
            <td>
              {{ roomPlanSearch | tmEnum: 'Feature' }}
            </td>
          </tr>
        </tbody>
      </table>
    }

    <h4>
      {{ 'selectPlan' | translate }}
    </h4>

    <ul class="list-group list-group-flush mb-5">
      @for (plan of sortedPlanList; track plan.PlanId) {
        <li class="list-group-item px-0 py-3">
          <div class="row g-3">
            <div class="col-12 col-md">
              <small>
                @if (plan | tmEnum: 'OptionalItems'; as meal) {
                  {{ meal }}
                } @else {
                  <!-- 구글 자동 번역 됨 -->
                  食事なし
                }
              </small>

              <h5 class="m-0" [innerHTML]="plan.PlanName"></h5>
            </div>

            <div class="d-flex d-md-block col-12 col-md-auto" translate="no">
              <span class="fs-2 text-primary">
                ¥{{ plan.TotalPrice | number }}
              </span>

              <div class="col col-md-12 text-end">
                @if (plan.PlanId === selectedPlan?.PlanId; as isSelected) {
                  <button class="btn btn-primary" [disabled]="isSelected">
                    {{ 'selected' | translate }}
                  </button>
                } @else {
                  <button
                    class="btn btn-outline-primary"
                    (click)="onPlanClick(plan)"
                  >
                    {{ 'BUTTON.Select' | translate }}
                  </button>
                }
              </div>
            </div>
          </div>
        </li>
      }
    </ul>

    <div #scrollTarget></div>

    @if (selectedPlan) {
      <h4 class>
        {{ 'bookingInfo' | translate }}
      </h4>

      <div class="mb-5">
        <trnty-tm-mrhst-booking-form
          [plan]="selectedPlan"
          (init)="onFormInit($event)"
        ></trnty-tm-mrhst-booking-form>
      </div>

      <h4 class="mb-0">{{ 'summary' | translate }}</h4>

      <p>
        <small class="text-body-secondary">
          {{ 'notResponsibleToThisGoods' | translate: { brandNm } }}
        </small>
      </p>

      <table class="table table-borderless mb-5">
        <tbody class="fs-0875 fs-md-6">
          <tr>
            <th class="w-25">{{ 'Hotel' | translate }}</th>
            <td>{{ hotel.Name }}</td>
          </tr>

          <tr>
            <th class="w-25">{{ 'Room' | translate }}</th>
            <td [innerHTML]="roomPlanSearch.RoomName"></td>
          </tr>

          <tr>
            <th class="w-25">{{ 'plan' | translate }}</th>
            <td [innerHTML]="selectedPlan.PlanName"></td>
          </tr>

          <tr>
            <th class="w-25">{{ 'Schedule' | translate }}</th>
            <td>
              {{ bookingCondition.checkInDt }} ~
              {{ bookingCondition.checkOutDt }}
            </td>
          </tr>

          <tr>
            <th class="w-25">{{ 'People' | translate }}</th>
            <td>
              {{
                'unit.people' | translate: { cnt: bookingCondition.tourNumber }
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <h4 class="mb-0">
        {{ 'cancelRule' | translate }}
      </h4>

      <p>
        <small class="text-body-secondary">
          {{ 'refundText' | translate }}
        </small>
      </p>

      <div class="mb-5">
        <app-tm-cancel-policy-table
          [cancelPolicy]="selectedPlan.CancelPolicy"
        ></app-tm-cancel-policy-table>
      </div>

      <h4>{{ 'setleAmt' | translate }}</h4>

      <div class="display-6 mb-5 text-primary" translate="no">
        ￥{{ selectedPlan.TotalPrice | number }}
      </div>

      <div class="d-grid col-4 mx-auto">
        <button class="btn btn-primary btn-lg" (click)="onBookingClick()">
          {{ 'confirm' | translate }}
        </button>
      </div>
    }
  }
</div>
