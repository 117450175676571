import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { Language } from './services/google-translation.service';

/**
 * ngx-i18n 커스텀 TranslateLoader
 */
export class TranslateVersionLoader implements TranslateLoader {
  /**
   * 번역 파일 캐싱용 오브젝트
   *
   * translate-with-google 파이프에서 번역 파일을 여러번 호출하기때문에 필요
   */
  private translationObject: { [key in Language]?: any } = {};

  /**
   * 투어니티에서 직접 관리하는 언어 목록
   *
   * app.config에서 입력
   */
  private trntyManagedLanguageSet: Set<Language>;

  constructor(
    private httpClient: HttpClient,
    private trntyManagedLanguageList: Language[],
  ) {
    // 비교하기 편하게 세트로 저장
    this.trntyManagedLanguageSet = new Set(this.trntyManagedLanguageList);
  }

  /**
   * @inheritdoc
   */
  getTranslation(lang: Language): Observable<any> {
    if (!this.trntyManagedLanguageSet.has(lang)) {
      // 직접 관리하는 언어 아니면 기본 언어 대신 아무것도 반환 안함
      return of(undefined);
    }

    if (this.translationObject[lang]) {
      // 반복호출 방지하기 위해 캐시된 번역 반환
      return of(this.translationObject[lang]);
    }

    // 항상 새로 불러오도록 타임스탬프 추가
    return this.httpClient
      .get(`/assets/i18n/${lang}.json?v=${new Date().valueOf()}`)
      .pipe(
        tap((translation) => {
          if (translation) {
            // 캐싱
            this.translationObject[lang] = translation;
          }
        }),
      );
  }
}
