import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PageRepositoryService } from 'src/lib/repository/abstract-repository.service';
import { TmBookingResponse } from './tm-booking.model';

@Injectable({
  providedIn: 'root',
})
export class TmBookingApi extends PageRepositoryService<any> {
  override baseUri = `/pms/temairazu/booking`;

  constructor(protected override http: HttpClient) {
    super(http);
  }

  makeBooking(hotelId: number, body?: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiServerUrl}${this.baseUri}/${hotelId}`, body, {})
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  getBooking(
    hotelId: number,
    BookingId: string,
    MailAddress: string,
  ): Observable<TmBookingResponse> {
    return this.http
      .post<any>(`${this.apiServerUrl}${this.baseUri}/login/${hotelId}`, {
        MailAddress,
        BookingId,
      })
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  getBookingWithAuthKey(
    hotelId: number,
    authKey?: any,
  ): Observable<TmBookingResponse> {
    return this.http
      .get<any>(
        `${this.apiServerUrl}${this.baseUri}/${hotelId}?auth_key=${authKey}`,
        {},
      )
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  fixBooking(hotelId: number, body?: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiServerUrl}${this.baseUri}/fix/${hotelId}`, body, {})
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  updateBooking(hotelId: number, body?: any): Observable<any> {
    return this.http
      .patch<any>(`${this.apiServerUrl}${this.baseUri}/${hotelId}`, body, {})
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  deleteBooking(hotelId: number, params?: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .delete<any>(`${this.apiServerUrl}${this.baseUri}/${hotelId}`, {
        params: httpParams,
      })
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  getModifyPrice(hotelId: number, body?: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiServerUrl}${this.baseUri}/modify/getprice/${hotelId}`,
        body,
        {},
      )
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }

  getCancelFee(hotelId: number, params?: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .get<any>(
        `${this.apiServerUrl}${this.baseUri}/cancel/calcfee/${hotelId}`,
        { params: httpParams },
      )
      .pipe(
        map((res) => res),
        this.handleError(),
      );
  }
}
