import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { DrawerComponent } from './drawer.component';

@NgModule({
  declarations: [DrawerComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [DrawerComponent],
})
export class DrawerModule {}
